import { Combobox, UseComboboxStateChange } from '@twilio-paste/core/combobox'
import { Input } from '@twilio-paste/core/input'
import { Label } from '@twilio-paste/core/label'
import { Box } from '@twilio-paste/core/box'
import { TextArea } from '@twilio-paste/core/textarea'
import { FormEvent, useCallback, useEffect } from 'react'
import { Button } from '@twilio-paste/core/button'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '@twilio-paste/core/text'
import { sessionDataHandler } from '../sessionDataHandler'
import {
  addNotification,
  changeEngagementPhase,
  updatePreEngagementData,
} from '../store/actions/genericActions'
import { initSession } from '../store/actions/initActions'
import { AppState, EngagementPhase } from '../store/definitions'
import { Header } from './Header'
import { notifications } from '../notifications'
import { NotificationBar } from './NotificationBar'
import {
  introStyles,
  fieldStyles,
  titleStyles,
  formStyles,
} from './styles/PreEngagementFormPhase.styles'
import { isWebChatOpen } from '../utils/validateWebchatHours'

import langs from './supportedLanguages.json'
import { useSafeTranslation } from '../i18n/useSafeTranslation'
import { useTranslation } from 'react-i18next'
import { getDaylightSavingsAwareTz } from '../utils/time'
import { getWebchatHours } from '../utils/checkHolidayDates'

type UserLanguage = { code: string; language: string }
const supportedLanguages = langs.webchatLanguages

const DEFAULT_LANGUAGE: UserLanguage = { code: 'en', language: 'English' }

const getHtmlDocLang: () => UserLanguage = () => {
  // On the Solunaapp.com website, if the user uses the "overall" language selector for the entire
  // website, it changes the HTML document's <html lang="XX"> attribute to the newly-selected language.

  const documentLangCode = document.documentElement.lang
  const lang =
    supportedLanguages.find(
      (supportedLang) =>
        supportedLang.code.toLowerCase() === documentLangCode.toLowerCase()
    ) ?? DEFAULT_LANGUAGE

  return { code: lang.code, language: lang.language }
}

export const PreEngagementFormPhase = () => {
  const t = useSafeTranslation()
  const { t: unsafeT } = useTranslation()
  const { name, email, query, userlanguage } =
    useSelector((state: AppState) => state.session.preEngagementData) || {}

  const currentLangItem = getHtmlDocLang()

  useEffect(() => {
    dispatch(
      updatePreEngagementData({ userlanguage: currentLangItem.language })
    )
  }, [])

  const dispatch = useDispatch()

  const handleSubmit = async (e: FormEvent) => {
    if (isWebChatOpen()) {
      e.preventDefault()
      dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }))
      try {
        const data = await sessionDataHandler.fetchAndStoreNewSession({
          formData: {
            friendlyName: name,
            email,
            query,
            userlanguage,
          },
        })
        await initSession({
          token: data.token,
          conversationSid: data.conversationSid,
        })(dispatch)
      } catch (err) {
        dispatch(
          addNotification(
            notifications.failedToInitSessionNotification(
              (err as Error).message
            )
          )
        )
        dispatch(
          changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm })
        )
      }
    } else {
      dispatch(addNotification(notifications.webchatNotOpenNotification()))
      dispatch(
        changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm })
      )
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSubmit(e)
    }
  }

  const handleSelectLanguage = useCallback((e: UseComboboxStateChange<any>) => {
    const selectedLangItem = e.selectedItem
    // We save the full English name of the selected language to
    // state.preEngagementData.userlanguage, even if the
    // user selected a different language, because this is what is going to
    // be displayed in Twilio.
    dispatch(
      updatePreEngagementData({ userlanguage: selectedLangItem.language })
    )
  }, [])

  const translatedLangLabel = (langObj: UserLanguage) => {
    return langObj?.code
      ? unsafeT(`language.${langObj.code}`.toLowerCase())
      : null
  }

  const californiaTimeZone = getDaylightSavingsAwareTz({
    timeZone: 'America/Los_Angeles',
  })

  const { webchatHoursStart, webchatHoursEnd } = getWebchatHours()
  const tz =
    californiaTimeZone === 'PDT' || californiaTimeZone === 'PST'
      ? californiaTimeZone
      : ''

  return (
    <>
      <Header />
      <NotificationBar />
      {isWebChatOpen() ? (
        <Box
          as="form"
          data-test="pre-engagement-chat-form"
          onSubmit={handleSubmit}
          {...formStyles}
        >
          <Text {...titleStyles} as="h3">
            {t('preEngagementHiThere')}
          </Text>
          <Text {...introStyles} as="p">
            {t('preEngagementIntroWithHours', {
              timezoneAbbrev: tz,
              openingHours: `${webchatHoursStart}:00`,
              closingHours: `${webchatHoursEnd + 1}:00`,
            })}
          </Text>
          <Box {...fieldStyles}>
            <Label htmlFor="name">{t('preEngagementName')}</Label>
            <Input
              type="text"
              placeholder={t('preEngagementNameInstructions')}
              name="name"
              data-test="pre-engagement-chat-form-name-input"
              value={name}
              onChange={(e) =>
                dispatch(updatePreEngagementData({ name: e.target.value }))
              }
              required
            />
          </Box>
          <Box {...fieldStyles}>
            <Label htmlFor="email">{t('preEngagementEmail')}</Label>
            <Input
              type="email"
              placeholder={t('preEngagementEmailInstructions')}
              name="email"
              data-test="pre-engagement-chat-form-email-input"
              value={email}
              onChange={(e) =>
                dispatch(updatePreEngagementData({ email: e.target.value }))
              }
              required
            />
          </Box>
          <Box {...fieldStyles}>
            <Combobox
              name="userlanguage"
              data-testid="pre-engagement-chat-form-userlanguage-combobox"
              items={supportedLanguages}
              itemToString={(langObj) => translatedLangLabel(langObj) ?? ''}
              initialSelectedItem={currentLangItem}
              optionTemplate={translatedLangLabel}
              labelText={t('preEngagementLanguage')}
              onInputValueChange={handleSelectLanguage}
            />
          </Box>

          <Box {...fieldStyles}>
            <Label htmlFor="query">{t('preEngagementHowCanWeHelp')}</Label>
            <TextArea
              placeholder={t('preEngagementInstructions')}
              name="query"
              data-test="pre-engagement-chat-form-query-textarea"
              value={query}
              onChange={(e) =>
                dispatch(updatePreEngagementData({ query: e.target.value }))
              }
              onKeyPress={handleKeyPress}
              required
            />
          </Box>

          <Button
            variant="primary"
            type="submit"
            data-test="pre-engagement-start-chat-button"
          >
            {t('preEngagementStartChat')}
          </Button>
        </Box>
      ) : (
        <Box {...formStyles}>
          <Text {...titleStyles} as="h3">
            {t('preEngagementChatClosed')}
          </Text>
          <Text {...introStyles} as="p">
            {t('preEngagementChatClosedExplanationWithHours', {
              timezoneAbbrev: tz,
              openingHours: `${webchatHoursStart}:00`,
              closingHours: `${webchatHoursEnd + 1}:00`,
            })}
          </Text>
        </Box>
      )}
    </>
  )
}
