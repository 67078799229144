import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export const checkHolidayDates = (
  dates: string[],
  timezone = 'America/Los_Angeles',
  checkHolidays = false
) => {
  if (!checkHolidays) {
    return false
  }
  return dates.some((date) => {
    const now = dayjs.tz(Date.now(), timezone)
    const year = now.get('year')
    const holiday = dayjs.tz(String(year).concat('-', date), timezone)
    return (
      holiday.get('date') === now.get('date') &&
      holiday.get('month') === now.get('month')
    )
  })
}

export const getWebchatHours = () => {
  const timezone = process.env.CONTACT_CENTER_TIMEZONE
  const holidayDates = process.env.CONTACT_CENTER_HOLIDAY_DATES
    ? JSON.parse(process.env.CONTACT_CENTER_HOLIDAY_DATES)
    : []

  const isHolidayDate = checkHolidayDates(holidayDates, timezone, true)

  const webchatHoursStart = isHolidayDate
    ? Number(process.env.HOLIDAY_CONTACT_CENTER_BUSINESS_HOURS_START)
    : Number(process.env.DEFAULT_CONTACT_CENTER_BUSINESS_HOURS_START)
  const webchatHoursEnd = isHolidayDate
    ? Number(process.env.HOLIDAY_CONTACT_CENTER_BUSINESS_HOURS_END)
    : Number(process.env.DEFAULT_CONTACT_CENTER_BUSINESS_HOURS_END)

  return {
    webchatHoursEnd,
    webchatHoursStart,
  }
}
